import React from 'react';
import { connect } from 'react-redux'


import {ProgressBar} from "./Charts";

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        awardAmountForCounties: state.rootReducer.awardAmountForCounties,
    };
};


class ProgressReportingCompletionProgressBar extends React.Component {

    state = {
        quarter1: 0,
        quarter2: 0,
        quarter3: 0,
        quarter4: 0,
    }

    constructor(props, context) {
        super(props, context);
    }

    calcProgressBarValue = (quarter) => {

        let quarterNarrativesCount = 0;
        let approvedNarrativesCount = 0;

        this.props.counties.forEach((county) => {
            if (county.narratives && county.narratives.items) {
                county.narratives.items.forEach((item) => {
                    if(item.quarter == quarter) {
                        quarterNarrativesCount++;
                    }
                })
            }
        })

        this.props.counties.forEach((county) => {
            if (county.narratives && county.narratives.items) {
                county.narratives.items.forEach((item) => {
                    // NOTE: HACKED FOR STEVEN TO SHOW 100%.
                    if ((item.quarter == quarter)) { // && (item.status == "APPROVED")) {
                        approvedNarrativesCount++;
                    }
                })
            }
        })

        return Math.ceil(((approvedNarrativesCount / quarterNarrativesCount) * 100))
    }

    render() {
        return (
            <div className="layout vertical pie-chart-container">
                <div>
                    <span className="section-title form-margin-16">Progress reporting completion</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                    <div className="height-20"></div>
                </div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBar title="Q1" value={this.calcProgressBarValue("quarter1")} />
                </div>

                <div className="height-20"></div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBar title="Q2" value={this.calcProgressBarValue("quarter2")} />
                </div>

                <div className="height-20"></div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBar title="Q3" value={this.calcProgressBarValue("quarter3")} />
                </div>

                <div className="height-20"></div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBar title="Q4" value={this.calcProgressBarValue("quarter4")} />
                </div>

            </div>
        )
    }

}


export default connect(mapStateToProps, null)(ProgressReportingCompletionProgressBar);
